<template>
    <div class="mb-2">
        <div v-if="checkingIn">
            <b-row>
                <b-col cols="12">
                    <Loader text="Laddar upp egenkontroll(er)" />
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row v-if="isOffline">
                <b-col cols="12">
                    <b-alert style="padding: 6px 11px;height:45px;" :show="true">
                        <strong style="vertical-align: sub;"><font-awesome-icon icon="signal-slash" /> Du är i offlineläge</strong>
                    </b-alert>
                </b-col>
            </b-row>  
            <b-tabs>
                <b-tab>
                    <template #title>
                        Kund
                    </template>

                    <b-row v-if="!isOffline">
                        <b-col cols="12">
                            <CustomerSelectList />
                        </b-col>
                    </b-row>
            
                    <b-row v-if="selectedCustomer && !isOffline">
                        <b-col xl="6"
                               lg="12"
                               md="12"
                               xs="12"
                               sm="12"
                               class="mt-4">
                            <ExpiringSchedules class="building-overview--widget"
                                               header="Scheman som förfaller"
                                               :customerId="selectedCustomer.id"
                                               :key="selectedCustomer.id" />
                        </b-col>
                        <b-col xl="6"
                               lg="12"
                               md="12"
                               xs="12"
                               sm="12"
                               class="mt-4">
                            <ActiveControls class="building-overview--widget"
                                            header="Aktiva kontroller"
                                            :customerId="selectedCustomer.id"
                                            :key="selectedCustomer.id" />
                        </b-col>
                    </b-row>
                    <b-row v-if="selectedCustomer && !isOffline">
                        <b-col cols="12"
                               class="mt-4">
                            <ActiveLacksWidget class="building-overview--widget"
                                               header="Åtgärdsplaner"
                                               :customerId="selectedCustomer.id"
                                               :key="selectedCustomer.id" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12"
                               class="mt-4"
                               v-if="selectedCustomer">
                            <CheckedOutControls class="building-overview--widget"
                                                :customerId="selectedCustomer.id" />
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab lazy v-for="dashbord in dashboards">
                    <template #title>
                        {{dashbord.name}}
                    </template>
                    <Dashboard :dashboard="dashbord" :customerId="selectedCustomer.id" ></Dashboard>
                </b-tab>             
            </b-tabs>
        </div>
    </div>
</template>
<script>
    import CustomerSelectList from '@/components/customer/CustomerSelectList';
    import ExpiringSchedules from '@/components/widgets/ExpiringSchedules';
    import ActiveControls from '@/components/widgets/ActiveControls';
    import ActiveLacksWidget from '@/components/widgets/ActiveLacksWidget';
    import CheckedOutControls from '@/components/widgets/CheckedOutControls';
    import Dashboard from '@/components/dashboard/dashboard';
    import { mapState } from 'vuex';
    import { get } from '@/helpers/api';
    import Loader from '@/components/Loader';
    export default {
        name: 'StartPage',
        components: {
            CustomerSelectList,
            ExpiringSchedules,
            ActiveControls,
            ActiveLacksWidget,
            CheckedOutControls,
            Loader,
            Dashboard
        },
    data() {
        return {
            dashboards: []
        };
    },
        async mounted() {
        await this.getData();
    },
        computed: {
            ...mapState({
                selectedCustomer: (state) => state.user.selectedCustomer,
                isOffline: (state) => state.user.isOffline,
                checkingIn: (state) => state.control.isCheckingIn
            })
        },
        methods: {
            async getData() {                
                await get('Dashboard', 'GetAllActive')
                    .then((x) => {
                        this.dashboards = x.data;
                    })
                    .catch((x) => {
                        // error handling.
                    });
            }
        }
    };
</script>

