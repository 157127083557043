<template>
    <div>
        <b-form-group class="mt-3">
            <b-row class="mb-1" align-h="end">
                <b-col xs="12" sm="6" xl="3" class="mb-2 mb-sm-0">
                    <b-form-datepicker v-model="startDate"
                                       placeholder="Startdatum"
                                       size="sm"
                                       :max="endDate"></b-form-datepicker>
                </b-col>
                <b-col xs="12" sm="6" xl="3">
                    <b-form-datepicker v-model="endDate"
                                       placeholder="Slutdatum"
                                       size="sm"
                                       :min="startDate"></b-form-datepicker>
                </b-col>
            </b-row>
        </b-form-group>
        <b-row class="mt-2 mb-4">
            <b-col v-for="widget in widgets"
                   :key="widget.Id"
                   :md="widget.cols"                
                   class="mb-4">
                <component :is="widget.componentName" :widget="widget" :customerId="customerId" :startDate="startDate" :endDate="endDate" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import Widgets from "@/components/widgets";

export default {
        components: { ...Widgets },
        props: ["dashboard", "customerId"],
        data() {
            return {
                widgets: [],                
                    startDate: this.getOneYearAgo(),
                    endDate: this.getToday()
                
            };
        },
        async mounted() {            
            await this.getWidgets();
        },
        methods: {
         async getWidgets() {
                await get('Dashboard', `GetWidgets/${this.dashboard.id}`)
                    .then((x) => {
                        this.widgets = x.data;
                    })
                    .catch((x) => {
                    });
            },
            getToday() {
                const today = new Date();
                return today.toISOString().substr(0, 10);
            },
            getOneYearAgo() {
                const today = new Date();
                today.setFullYear(today.getFullYear() - 1); 
                return today.toISOString().substr(0, 10); 
            }

        }
};
</script>